import React, { useEffect, useState } from 'react'
// import { CardElevation } from '@findep/mf-landings-core'
import PropTypes from 'prop-types'
// import CarouselImg from '../img/CarouselImg.compilable'
import {
  // imageCss, 
  TextHeader, TextBox, TextScoreFontBold, TextScoreFontNormal
} from '../../styles/lo-sentimos'
import { Grid, Box, Card, Backdrop, Snackbar } from '@material-ui/core';
import { MaterialInput, ButtonDegrade } from '@findep/mf-landings-core'
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import scoreImg from '../../assets/lo-sentimos/score.png'
import messageImg from '../../assets/lo-sentimos/message.png'
import historial from '../../assets/lo-sentimos/historial.png'
import puntualidad from '../../assets/lo-sentimos/puntualidad.png'
import usodecredito from '../../assets/lo-sentimos/usodecredito.png'
import antiguedad from '../../assets/lo-sentimos/antiguedad.png'
import banner from '../../assets/lo-sentimos/banner.png'
import invitacion from '../../assets/lo-sentimos/invitacion.png'
import appstore from '../../assets/lo-sentimos/appstore.png'
import axios from 'axios';
import playstore from '../../assets/lo-sentimos/playstore.png'
import qr from '../../assets/lo-sentimos/qr.png'
import ArcoirisAppFinanciera from '../elements/ArcoirisAppFinanciera';
import { css } from '@emotion/react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import QRCode from "react-qr-code";

const bDrop = css`  
  z-index:9999;
  color: '#fff';
`

function LoSentimosCardAppFinanciera({ data, companyName }) {

  const [errorNip, setErrorNip] = useState(false)
  const [score, setScore] = useState(0)
  const [infoScore, setInfoScore] = useState([])
  const [credditApplication, setCreditApplication] = useState("");
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState("email");
  const [codigo, setCodigo] = useState("code");
  const [qrLink, setQrLink] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const saveGtmcolor = (color) => {
    let puntaje
    switch (color) {
      case 'BLUE': puntaje = '950'; break;
      case 'GREEN': puntaje = '700'; break;
      case 'YELLOW': puntaje = '400'; break;
      case 'ORANGE': puntaje = '0'; break;
      //   case 'GRAY_1':  puntaje='100'; break;
      //   case 'GRAY_2':  puntaje='0'; break;
      case 'RED': puntaje = '0'; break;
      default: puntaje = '0'; break;

    }
    return puntaje
  }


  useEffect(() => {
    sessionStorage.clear()
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores)
    setErrorNip(urlParams.has('nip-error'));

    let cs = ''
    if (urlParams.has('colorScore')) {
      cs = urlParams.get('colorScore')
    }

    if (cs!=='') {
      const valorcolor = saveGtmcolor(cs)
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'score',
        'color': `${cs}`,
        'valorColor': `${valorcolor}`
      });
    }

  }, [])


  useEffect(() => {
    setLoading(true);

    const urlParams = new URLSearchParams(window.location.search);
    const creditApplicationId = urlParams.get('creditApplicationId');
    setCreditApplication(creditApplicationId)

    const getData = async () => {
      const result = await axios.get(`/web/landing/${creditApplicationId}/${userEmail}/${codigo}`);

      if (result.data.score !== 0) {
        setScore(result.data.score);
        setUserEmail(result.data.email)
        setCodigo(result.data.codigo)

        setQrLink(`https://indfin.onelink.me/bbjQ/tlkrpbw3?deep_link_value=${result.data.codigo}`)

        setInfoScore(
          [
            {
              "name": "Puntualidad",
              "info": "Pagos de tus créditos hechos en tiempo",
              "value": result.data.pagoTiempo,
            },
            {
              "name": "Uso de crédito",
              "info": "Manejo del financiamiento en cuentas activas",
              "value": result.data.usoFinan,
            },
            {
              "name": "Antigüedad",
              "info": "Primer crédito ante instituciones financieras",
              "value": result.data.antig,
            },
            {
              "name": "Avance en pagos fijos",
              "info": "Avance de pago de tus créditos fijos",
              "value": result.data.avaFijos,
            }
          ])
      }
      setLoading(false);
      return result
    }
    getData()
  }, []);

  const getColor = (value, typeNameValidation) => {
    const dangerValue = typeNameValidation === "Puntualidad" ? 50 : typeNameValidation === "Avance en pagos fijos" ? 30 : 3;
    const warningValue = typeNameValidation === "Antigüedad" ? 7 : 80;
    var color = "#ffffff";
    if (value <= dangerValue) {
      color = "#f44336";
    } else if (value > dangerValue && value <= warningValue) {
      color = "#ffce00";
    } else {
      color = "#4caf50";
    }

    if (typeNameValidation === "Uso de crédito") {
      if (value <= 50) {
        color = "#4caf50";
      } else if (value > 50 && value <= 80) {
        color = "#ffce00";
      } else {
        color = "#f44336";
      }
    }
    return color;
  }

  const sendEmail = async () => {
    try {
      const result = await axios.get(`/web/landing/${credditApplication}/${userEmail}/${codigo}`);
      setIsSendingEmail(true)
    } catch (error) {
      setIsSendingEmail(false)
    }
  }

  return (<>
    {loading ? <Backdrop css={bDrop} open={loading} ><CircularProgress color="primary" /> </Backdrop> :

      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={6}>
          <br /> <br />
          <TextHeader>{errorNip ? 'El NIP fue erróneo en 3 ocasiones, para reclamar el dinero es nescesario comenzar un proceso nuevo' : data.textHeader}</TextHeader>
          <br />
          <TextBox>
            {data.textBox}
          </TextBox>
          <br />
          <TextHeader>{data.textAppTitle}</TextHeader>
          <br />
          <br />
          <TextBox style={{ fontWeight: "600", fontSize: "18px", }}>{data.textAppSubtitle}</TextBox>
          <br />
          <br />
          <br />
          <br />



          <Grid container spacing={8} justifyContent='center' >
            {score === 0 ? <>
              <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={6}>
                  <TextScoreFontNormal>
                    ¿CONOCES TU
                  </TextScoreFontNormal>
                  <TextScoreFontBold style={{ textAlign: "center", fontWeight: "bold" }}>SCORE DE CRÉDITO?</TextScoreFontBold>
                  <img src={scoreImg} alt="Iconos app" width="100%" />
                </Grid>
                <Grid item xs={6}>
                  <TextScoreFontNormal>Y ¿de qué se compone?</TextScoreFontNormal>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <img src={puntualidad} alt="Iconos app" width="70%" />
                    </Grid>
                    <Grid item xs={6}>
                      <img src={usodecredito} alt="Iconos app" width="90%" />
                    </Grid>
                    <Grid item xs={6}>
                      <img src={antiguedad} alt="Iconos app" width="80%" />
                    </Grid>
                    <Grid item xs={6}>
                      <img src={historial} alt="Iconos app" width="80%" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </> :
              <>
                <Card elevation={1} style={{ padding: "20px 100px 20px 100px" }} >
                  <Grid item xs={12} sm={12}>
                    <TextScoreFontNormal>
                      ESTE ES TU <span style={{ fontWeight: "bold" }}>SCORE</span> O MEJOR CONOCIDO COMO CALIFICACIÓN EN <span style={{ fontWeight: "bold" }}>BURÓ DE CRÉDITO</span>
                    </TextScoreFontNormal>
                    <br />
                    <ArcoirisAppFinanciera bcScore={score} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br />
                    <TextScoreFontNormal>Y ¿DE QUÉ SE COMPONE?</TextScoreFontNormal>
                    <br />
                    <Grid container spacing={1}>
                      {infoScore.map((item) =>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: "10px" }}>
                          <Card elevation={1} style={{ padding: "10px" }}>
                            <h1 style={{ textAlign: "center", fontSize: "30px", color: getColor(item.value, item.name) }}>{item.value}{item.name === "Antigüedad" ? " años" : "%"}</h1>
                            <p style={{ textAlign: "center", marginTop: "-30px", fontSize: "18px", fontWeight: "bold" }}>{item.name}</p>
                            <p style={{ textAlign: "center", marginTop: "-10px", fontSize: "15px", fontWeight: "300" }}>{item.info}</p>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </>
            }

            {/* IMAGEN BANNER START */}

            {
              score === 0 ? <>
                <Grid item xs={12}>
                  <img src={banner} alt="Iconos app" width="100%" />
                </Grid>
                <Grid item xs={6} style={{ marginTop: "-30px" }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <a href="https://indfin.onelink.me/bbjQ/tlkrpbw3" target="_blank" rel="noopener noreferrer">
                      <img src={invitacion} alt="Iconos app" width="100%" />
                    </a>
                  </Box>
                  <Grid container spacing={2} style={{ marginTop: "5px" }}>
                    <Grid item xs={6}>
                      <img src={appstore} alt="Iconos app" width="100%" style={{ marginTop: "-20px" }} />
                    </Grid>
                    <Grid item xs={6}>

                      <img src={playstore} alt="Iconos app" width="100%" style={{ marginTop: "-16px" }} />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ position: "relative", marginTop: "-30px" }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img src={qr} alt="Iconos app" width="100%" style={{ marginTop: "-5px" }} />
                  </Box>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <img src={messageImg} alt="Iconos app" width="100%" style={{ marginTop: "-15px" }} />
                </Grid>
              </> : <>
                <Grid item xs={12}>
                  <img src={banner} alt="Iconos app" width="100%" />
                </Grid>
                <Grid item xs={6} style={{ marginTop: "0px" }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing="2"
                  >
                    <TextHeader>
                      Tu código de registro es:
                    </TextHeader>
                    <br />
                    <Card elevation={1} style={{ padding: "10px 50px 10px 50px" }} >
                      <TextBox style={{ fontWeight: "600", fontSize: "18px", }}>
                        {codigo?.length === 8 && `${codigo.substring(0, 4)} ${codigo.substring(4, 8)}`}
                      </TextBox>
                    </Card>

                    <br />
                    <a href="https://indfin.onelink.me/bbjQ/tlkrpbw3" target="_blank" rel="noopener noreferrer">
                      <ButtonDegrade
                        style={{ background: "linear-gradient(90deg, #970d55 0%, #c72433 100%)" }}
                        id="tus-datos-continuar-solicitud-button"
                        icon={ArrowForwardIcon}
                        textButton='Descargar la app'
                        onClick={() => { }}
                      />
                    </a>
                    <br />

                    <Grid container spacing={2} style={{ marginTop: "5px" }}>
                      <Grid item xs={6}>
                        <img src={appstore} alt="Iconos app" width="100%" style={{ marginTop: "-20px" }} />
                      </Grid>
                      <Grid item xs={6}>
                        <img src={playstore} alt="Iconos app" width="100%" style={{ marginTop: "-16px" }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ position: "relative", marginTop: "-30px" }}>
                  <TextBox style={{ textAlign: "center", fontSize: "18px", marginLeft: "20px", paddingBottom: "10px" }}>O escanea el código</TextBox>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "70%" }}
                      value={qrLink}
                      viewBox={`0 0 256 256`}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "-30px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing="2"
                  >
                    <TextBox style={{ fontWeight: "500", fontSize: "18px", }}>
                      Te enviaremos esta información a tu correo electrónico
                    </TextBox>
                    <Grid item xs={8} style={{ marginTop: "5px" }}>
                      <MaterialInput
                        id="tus-datos-correo-electronico"
                        type="email"
                        label="Correo Electrónico"
                        value={userEmail}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        accept="\w@._\-"
                        transform={(value) => value.slice(0, 50)}
                        className="is-mobile ml10 pb15 is-4 expand"
                        onChange={(_event, component) => setUserEmail(_event.target.value)}
                        errorMessage="Debe ingresar un correo eléctronico válido"
                        inputmode="email"
                        autocomplete="email"
                      />
                    </Grid>
                    <ButtonDegrade
                      style={{ background: "linear-gradient(90deg, #970d55 0%, #c72433 100%)" }}
                      id="tus-datos-continuar-solicitud-button"
                      icon={ArrowForwardIcon}
                      textButton='Enviar correo'
                      onClick={() => sendEmail()}
                    />
                  </Grid>
                </Grid>

                <br />
                <Grid item xs={12}>
                  <img src={messageImg} alt="Iconos app" width="100%" style={{ marginTop: "-15px" }} />
                </Grid>
              </>

            }

          </Grid>
        </Grid>
        <Snackbar open={isSendingEmail} autoHideDuration={2500} onClose={() => setIsSendingEmail(false)}>
          <Alert variant="filled" severity="success" onClose={() => setIsSendingEmail(false)}>
            Correo electrónico enviado, revisa tu bandeja de entrada.
          </Alert>
        </Snackbar>
      </Grid>}
  </>
  )
}

LoSentimosCardAppFinanciera.propTypes = {
  data: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired
}

export default LoSentimosCardAppFinanciera